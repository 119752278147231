import * as React from "react";

function TwitterIcon(props) {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.18 28.922c11.995 0 18.555-9.93 18.555-18.541 0-.282-.006-.563-.018-.843a13.256 13.256 0 003.253-3.374 13.012 13.012 0 01-3.745 1.026 6.541 6.541 0 002.867-3.605 13.087 13.087 0 01-4.14 1.582 6.516 6.516 0 00-4.761-2.059 6.52 6.52 0 00-6.353 8.002A18.523 18.523 0 012.395 4.301a6.495 6.495 0 00-.883 3.276A6.51 6.51 0 004.414 13a6.478 6.478 0 01-2.954-.815v.083a6.52 6.52 0 005.231 6.388 6.534 6.534 0 01-2.945.112 6.528 6.528 0 006.092 4.526 13.09 13.09 0 01-8.1 2.79c-.526 0-1.045-.03-1.555-.09a18.47 18.47 0 009.997 2.927"
        fill="#F9F9F9"
      />
    </svg>
  );
}

export default TwitterIcon;
