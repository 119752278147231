import * as React from "react";

function InstagramIcon(props) {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16 4.364c-3.16 0-3.556.014-4.798.07-1.238.057-2.084.253-2.824.54a5.698 5.698 0 00-2.061 1.342 5.712 5.712 0 00-1.343 2.062c-.289.74-.485 1.586-.54 2.824-.056 1.241-.07 1.638-.07 4.798s.013 3.556.07 4.797c.057 1.239.253 2.084.54 2.824a5.702 5.702 0 001.342 2.062 5.698 5.698 0 002.06 1.343c.741.287 1.587.483 2.825.54 1.242.057 1.638.07 4.798.07s3.556-.013 4.797-.07c1.239-.056 2.085-.253 2.825-.54a5.694 5.694 0 002.06-1.343 5.713 5.713 0 001.343-2.061c.286-.74.483-1.587.54-2.825.056-1.241.071-1.637.071-4.797 0-3.16-.015-3.557-.07-4.798-.058-1.239-.255-2.084-.54-2.824a5.712 5.712 0 00-1.344-2.062 5.687 5.687 0 00-2.06-1.341c-.742-.288-1.588-.484-2.827-.54-1.241-.057-1.636-.071-4.797-.071H16zM14.956 6.46H16c3.107 0 3.475.01 4.702.067 1.135.052 1.75.241 2.16.4.544.211.93.463 1.338.87.407.408.66.796.87 1.339.16.41.35 1.025.401 2.16.056 1.226.068 1.595.068 4.7 0 3.106-.012 3.474-.068 4.701-.052 1.135-.241 1.75-.4 2.16-.211.543-.464.93-.871 1.337-.407.407-.794.66-1.337.87-.41.16-1.026.35-2.16.401-1.227.056-1.596.068-4.703.068s-3.475-.012-4.702-.068c-1.134-.052-1.75-.242-2.16-.401a3.606 3.606 0 01-1.338-.87 3.607 3.607 0 01-.871-1.338c-.16-.41-.35-1.025-.4-2.16-.057-1.227-.068-1.595-.068-4.702 0-3.108.011-3.474.067-4.701.052-1.135.242-1.75.4-2.16.212-.544.464-.931.872-1.339.407-.407.795-.66 1.338-.87.41-.16 1.026-.35 2.16-.402 1.074-.048 1.49-.063 3.658-.065v.003zm7.256 1.932a1.396 1.396 0 100 2.793 1.396 1.396 0 000-2.794zM16 10.024a5.976 5.976 0 10.001 11.952A5.976 5.976 0 0016 10.024zm0 2.097a3.879 3.879 0 110 7.758 3.879 3.879 0 010-7.758z"
        fill="#F9F9F9"
      />
    </svg>
  );
}

export default InstagramIcon;
