import * as React from "react";

function LinkedInIcon(props) {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_101_21)" fill="#F9F9F9">
        <path d="M30.43 27.83a.505.505 0 00.476-.325.492.492 0 00.03-.2c0-.372-.227-.55-.692-.55h-.752v1.948h.283v-.849h.347l.008.01.54.84h.302l-.58-.869.039-.005zm-.326-.196h-.328v-.658h.415c.215 0 .46.034.46.313 0 .32-.248.345-.55.345M23.055 25.388h-4.01V19.17c0-1.482-.026-3.39-2.085-3.39-2.088 0-2.407 1.614-2.407 3.282v6.324h-4.01V12.603h3.85v1.747h.053a4.2 4.2 0 011.61-1.552 4.25 4.25 0 012.188-.513c4.063 0 4.813 2.647 4.813 6.09l-.002 7.013zM6.02 10.856c-.46 0-.91-.135-1.293-.388a2.309 2.309 0 01-.857-1.034 2.283 2.283 0 01.504-2.51 2.348 2.348 0 012.535-.5c.426.174.79.469 1.045.848a2.288 2.288 0 01-.289 2.909 2.327 2.327 0 01-1.645.675zm2.005 14.532H4.01V12.603h4.014v12.785zM25.054 2.562H1.997a1.985 1.985 0 00-1.402.556A1.946 1.946 0 000 4.493v22.925c.006.518.22 1.013.594 1.375.375.363.88.563 1.403.558h23.057a1.99 1.99 0 001.406-.556c.376-.363.591-.858.598-1.377V4.49a1.95 1.95 0 00-.598-1.376 1.99 1.99 0 00-1.406-.555" />
        <path d="M30.138 25.887c-.488.004-.954.2-1.296.544a1.817 1.817 0 00.012 2.577 1.855 1.855 0 002.603 0 1.818 1.818 0 00.013-2.577 1.855 1.855 0 00-1.297-.544h-.035zm0 3.444a1.63 1.63 0 01-.903-.255 1.606 1.606 0 01-.608-.709 1.588 1.588 0 01.321-1.751 1.622 1.622 0 011.757-.377c.298.117.554.318.736.578.182.261.282.57.288.886v.027a1.56 1.56 0 01-.44 1.118c-.29.3-.69.473-1.109.482h-.042" />
      </g>
      <defs>
        <clipPath id="clip0_101_21">
          <path fill="#fff" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default LinkedInIcon;
